import React from 'react';
import LinkComponent from '../components/LinkComponent'
import BackgroundImage from '../components/BackgroundImage'
import { isValidValue, ImageOverlay } from '../utils'

const ImageBlock = ({ imageBlock }) => {
  const alignmentClass = (alignment) => {
    const valueCheck = isValidValue(alignment) && !!imageBlock.images && imageBlock.images.length > 1;
    if (valueCheck && alignment === 'left') {
      return ['is-8', 'is-4'];
    }
    if (valueCheck && alignment === 'right') {
      return ['is-4', 'is-8'];
    }
    if (valueCheck && alignment === 'center') {
      return ['is-6', 'is-6'];
    }
    return imageBlock.images.length > 1 ? ['is-6', 'is-6'] : ['is-12']
  }

  return (
    imageBlock.images.map((image, index) => {
      const alignment = alignmentClass(imageBlock.alignment)[index];
      return (
        <div className={`column ${alignment} image-block hover-scale similar-property-container`} key={`ImageBlock--${index}`}>
          <LinkComponent linkObject={image.linkObject}>
            <BackgroundImage classes="image-block-image similar-property" imageInfo={image.imageObject.image}>
              {image.isTitle ?
                <div className={getTitleClass(image, alignment)}>
                  {image.textObject.text}
                  <hr className="hr-white" />
                </div>
                :
                <div className="similar-property-overlay">
                  <div className="similar-property-overlay-text">
                    <h4 className="title similar-property-title">{image.textObject.text}</h4>
                  </div>
                </div>
              }
              <ImageOverlay overlay={image.imageObject.overlay} />
            </BackgroundImage>
          </LinkComponent>
        </div>   
      )
    })
  );
}

export default ImageBlock

const getTitleClass = (component, alignment) => {
  const color = component.textObject.textColor;
  const isTitle = component.isTitle;
  let classes = 'home-text home-component';
  if (color === 'black') {
    classes += ' home-text-black';
  } else {
    classes += ' home-text-white';
  }
  if (isTitle) {
    classes += ' home-text-title';
  }
  if (alignment === "is-12") {
    classes += ' home-text-title-large';
  }
  return classes;
}