import React from 'react';
import spinner from '../img/ball-triangle.svg';

const LoadingModal = ({ isOpen, modalText }) => {
  if (isOpen) {
    return (
      <div className='loading-modal'>
        <div className='loading-content'>
          <img
            className='loading-spinner'
            width={100}
            height={100}
            src={spinner}
            alt='Loading Spinner'
          />
          <h3>{modalText}</h3>
        </div>
      </div>
    );
  }
  return null;
};

export default LoadingModal;
