import React, { useState } from 'react'
import * as R from 'ramda'
import { navigate } from 'gatsby'
import { useFormik } from 'formik'
import axios from 'axios'
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select'
import { FaSearch } from 'react-icons/fa'
import moment from 'moment'
import enGB from "date-fns/locale/en-GB"
import ja from "date-fns/locale/ja"
import zhCN from "date-fns/locale/zh-CN"
import zhTW from "date-fns/locale/zh-TW"
import "react-datepicker/dist/react-datepicker.css"
import LoadingModal from '../components/LoadingModal'
import { buildQueryUrl, getLocalisationUrl, propertyGuestOptions } from '../utils'

const indicatorSeparatorStyle = {
  display: 'none'
};

const IndicatorSeparator = ({
  innerProps,
}) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};


const formatOptionLabel = ({ value, label, info }) => {
  const Tooltip = () => {
    if (!!info) {
      return (
        <div className="service-offering-tooltip">
          { info }
        </div>    
      );
    }
    return null;
  }
  return (
    <div className="service-offering-container">
      <div>{label}</div>
      <Tooltip />
    </div>
  );
}

const PropertySearchForm = ({ location, propertySearch, hotelIds, locale }) => {
  const [isSearchModalOpen, setSearchModalVisibility] = useState(false);
  const toggleSearchModal = () => setSearchModalVisibility(!isSearchModalOpen);

  const setCheckInDate = (e, form) => {
    const checkOutDate = moment(form.values.checkOut);
    const checkInDate = moment(e);
    if (checkOutDate.isBefore(checkInDate)) {
      form.setFieldValue('checkOut', checkInDate.add(1, 'day').toDate());
      return form.setFieldValue('checkIn', e);
    }
    return form.setFieldValue('checkIn', e);
  }

  const mappedHotelIds = hotelIds.map((id) => id.property.hotelId);

  const formik = useFormik({
    initialValues: {
      serviceType: propertySearch.serviceTypeOptions[0],
      location: propertySearch.options[1],
      checkIn: moment().add(1, 'month').toDate(),
      checkOut: moment().add(34, 'days').toDate(),
      guests: { label: "2", value: 2 }
    },
    onSubmit: values => {
      const appendServiceQuery = values.serviceType.value === "All" ? '' : `&serviceType=${values.serviceType.value}`;
      const locationValue = `?location=${values.location.value}&guests=${values.guests.value}${appendServiceQuery}`
      toggleSearchModal();
      const queryUrl = buildQueryUrl(values, mappedHotelIds);
      axios({
        method: 'get',
        url: queryUrl,
        auth: {
          username: process.env.GATSBY_ROOMBOSS_USER,
          password: process.env.GATSBY_ROOMBOSS_PASSWORD
        }
      })
        .then((response) => {
          let propertiesResponse = response.data;
          if (!!propertiesResponse.availableHotels) {
            const sortedResponse = response.data.availableHotels.map((hotel) => {
              if (!!hotel.availableRoomTypes) {
                const sortByAvailabilty = R.sortBy(R.prop('priceRetail'));
                const sortedAvailablilty = sortByAvailabilty(hotel.availableRoomTypes);
                return R.assoc('availableRoomTypes', sortedAvailablilty, hotel);
              }
              return hotel;
            });
            propertiesResponse = R.assoc('availableHotels', sortedResponse, response.data);
          }
          toggleSearchModal();
          navigate(
            `${getLocalisationUrl(location.pathname)}/accommodation${locationValue}`,
            { state: { propertiesResponse } }
          );
        })
        .catch(() => setSearchModalVisibility(false));
    }
  });

  switch (locale) {
    case "en":
      registerLocale("en", enGB);
    case "zhCN":
      registerLocale("zhCN", zhCN);
    case "zhTW":
      registerLocale("zhTW", zhTW);
    case "ja":
      registerLocale("ja", ja);
  }

  const getDateFormat = () => {
    if (locale === "en") {
      return "dd/MM/yyyy";
    } else {
      return "yyyy年 MM月 dd日";
    }
  };
  return (
    <form onSubmit={formik.handleSubmit} >
      <div className="form-field-wrapper form-select">
        <label htmlFor="location">{propertySearch.serviceTypeLabel}</label>
        <Select
          id="serviceType"
          name="serviceType"
          isSearchable={false}
          components={{ IndicatorSeparator }}
          formatOptionLabel={formatOptionLabel}
          styles={{ 
            menuList: () => ({ overflow: 'visible' }),
            indicatorsContainer: (base) => ({
              ...base,
              flex: 1
            }),            
            control: (base, state) => ({
              ...base,
              border: '1px solid black',
              boxShadow: 'none',
              '&:hover': {
                  border: '1px solid black',
              }
            })
          }}
          className="form-field select-form-field"
          options={propertySearch.serviceTypeOptions}
          value={formik.values.serviceType}
          onChange={(e) => formik.setFieldValue("serviceType", e)}
        />
      </div>
      <div className="form-field-wrapper form-select">
        <label htmlFor="location">{propertySearch.location}</label>
        <Select
          id="location"
          name="location"
          isSearchable={false}
          styles={{ 
            indicatorsContainer: (base) => ({
              ...base,
              flex: 1
            }),
            control: (base, state) => ({
              ...base,
              border: '1px solid black',
              boxShadow: 'none',
              '&:hover': {
                  border: '1px solid black',
              }
            })
          }}
          components={{ IndicatorSeparator }}
          className="form-field select-form-field"
          options={propertySearch.options}
          value={formik.values.location}
          onChange={(e) => formik.setFieldValue("location", e)}
        />
      </div>
      <div className="form-field-wrapper date">
        <div className="date-wrapper">
          <label htmlFor="checkIn">{propertySearch.checkIn}</label>
          <DatePicker
            id="checkIn"
            locale={locale}
            dateFormat={getDateFormat()}
            name="checkIn"
            onFocus={e => e.target.blur()}
            minDate={moment().toDate()}
            className="date-form-field form-field"
            selected={formik.values.checkIn}
            onChange={(e) => setCheckInDate(e, formik)}
          />
        </div>
        <div className="date-wrapper check-out-picker">
          <label htmlFor="checkOut">{propertySearch.checkOut}</label>
          <DatePicker
            id="checkOut"
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              }
            }}
            dateFormat={getDateFormat()}
            name="checkOut"
            locale={locale}
            popperPlacement="bottom-end"
            onFocus={e => e.target.blur()}
            minDate={formik.values.checkIn}
            maxDate={moment(formik.values.checkIn).add(20, 'days').toDate()}
            className="date-form-field checkout-form-field form-field"
            selected={formik.values.checkOut}
            onChange={(e) => formik.setFieldValue("checkOut", e)}
          />
        </div>
      </div>
      <div className="form-field-wrapper form-select guests-select">
        <label htmlFor="guests">{propertySearch.guests}</label>
        <Select
          className="form-field select-form-field"
          options={propertyGuestOptions}
          isSearchable={false}
          styles={{ 
            indicatorsContainer: (base) => ({
              ...base,
              flex: 1
            }),            
            control: (base, state) => ({
              ...base,
              border: '1px solid black',
              boxShadow: 'none',
              '&:hover': {
                  border: '1px solid black',
              }
            })
          }}
          components={{ IndicatorSeparator }}
          defaultValue={propertyGuestOptions[1]}
          onChange={(e) => formik.setFieldValue('guests', e)}
          placeholder="Number of guests"
        />
      </div>
      <div className="button-container">
        <button className="btn btn-main hero-search-button" type="submit">{!!propertySearch.search ? propertySearch.search : <FaSearch />}</button>
      </div>
      <LoadingModal isOpen={isSearchModalOpen} modalText={propertySearch.findingProperties} />
    </form>
  );
}

export default PropertySearchForm
