import React from 'react';
import PropertySearchForm from './PropertySearchForm'
import BackgroundImage from './BackgroundImage'

const Hero = ({
  heroImageObject,
  heroTitle,
  location,
  hotelIds,
  propertySearch,
  locale
}) => (
    <BackgroundImage classes={"hero-background-image full-width vertically-centered image-centered"} imageInfo={heroImageObject.image}>
      <div className="hero-booking-form-container">
        <div className="hero-booking-form">
          <PropertySearchForm location={location} propertySearch={propertySearch} hotelIds={hotelIds} locale={locale} />
        </div>
      </div>
      <div className="image-overlay" style={{ backgroundColor: `rgba(0,0,0,${heroImageObject.overlay / 100})` }}></div>
      <h1 className="title hero-title">{heroTitle}</h1>
    </BackgroundImage>
  );

export default Hero
