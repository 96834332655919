import React from 'react'
import LinkComponent from '../components/LinkComponent'
import BackgroundImage from '../components/BackgroundImage'
import { ImageOverlay } from '../utils'
import Image from './Image';

const FeaturedItems = ({ featuredItems }) => (
  <div className="container section-container">
    <div className="columns">
      <LinkComponent linkObject={featuredItems.leftImage.linkObject} className="column is-4 featured-item-container hover-scale">
        <BackgroundImage classes={"featured-background-image"} imageInfo={featuredItems.leftImage.imageObject.image}>
          <ImageOverlay overlay={ featuredItems.leftImage.imageObject.overlay } />
          <div className={`service-icon-container haku-life-icon`}>
            <Image imageInfo={{ image: featuredItems.leftImage.icon }} />
          </div>
        </BackgroundImage>
        <div className="featured-item-text-container">
          <div className="featured-item-text">
            <h2>{featuredItems.leftImage.textObject.title}</h2>
            { featuredItems.leftImage.textObject.text }
          </div>
          <div className="featured-item-subtext">
              { featuredItems.leftImage.textObject.subtext }
            </div>
        </div>
      </LinkComponent>

      <LinkComponent linkObject={featuredItems.middleImage.linkObject} className="column is-4 featured-item-container hover-scale">
          <BackgroundImage classes={"featured-background-image"} imageInfo={featuredItems.middleImage.imageObject.image}>
            <ImageOverlay overlay={featuredItems.middleImage.imageObject.overlay} />
            <div className={`service-icon-container`}>
              <Image imageInfo={{ image: featuredItems.middleImage.icon }} />
            </div>
          </BackgroundImage>
        <div className="featured-item-text-container">
          <div className="featured-item-text">
            <h2>{featuredItems.middleImage.textObject.title}</h2>
            {featuredItems.middleImage.textObject.text}
          </div>
          <div className="featured-item-subtext">
            { featuredItems.middleImage.textObject.subtext }
          </div>
        </div>
      </LinkComponent>

      <LinkComponent linkObject={featuredItems.rightImage.linkObject} className="column is-4 featured-item-container">
            <BackgroundImage classes={"featured-background-image hover-scale"} imageInfo={featuredItems.rightImage.imageObject.image}>
              <ImageOverlay overlay={featuredItems.rightImage.imageObject.overlay} />
              <div className={`service-icon-container`}>
                <Image imageInfo={{ image: featuredItems.rightImage.icon }} />
              </div>
            </BackgroundImage>
        <div className="featured-item-text-container">
          <div className="featured-item-text">
            <h2>{featuredItems.rightImage.textObject.title}</h2>
            {featuredItems.rightImage.textObject.text}
          </div>
          <div className="featured-item-subtext">
            { featuredItems.rightImage.textObject.subtext }
          </div>
        </div>
      </LinkComponent>

    </div>
  </div>
);

export default FeaturedItems
