import React from 'react';
import ImageBlock from './ImageBlock'
import { HTMLContent } from './Content';


const IndexPageComponents = ({ pageComponents }) => (
  <div>
  {pageComponents.map((component, index) => {
    if (component.textComponent === "") {
      return null;
    }
    if (!!component.textComponent) {
      return (
        <div className="index-text-container" key={ `IndexPageComponent--${index}` } style={{ 'text-align': component.alignment }}>
        <HTMLContent content={component.textComponent} />
        </div>
      )
    }
    return (      
      <div className="container section-container" key={ `IndexPageComponent--${index}` }>
        <div className="columns">
          <ImageBlock imageBlock={ component } />
        </div>
      </div>
    );
  })}
  </div>
);

export default IndexPageComponents